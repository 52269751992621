<template>
  <div class="home">
    <Book />
    <Showoff />
    <Caseshow />
    <Partner />
  </div>
</template>
<script>
import Book from "@/components/pages/home/Book.vue";
import Showoff from "@/components/pages/home/Showoff.vue";
import Caseshow from "@/components/pages/home/Caseshow.vue";
import Partner from "@/components/pages/home/Partner.vue";

export default {
  components: {
    Book,
    Showoff,
    Caseshow,
    Partner,
  },
  data() {
    return {};
  },
};
</script>
<style scoped>
</style>
