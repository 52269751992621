<template>
  <!-- 经典案例 -->
  <div class="head">
    <div class="head_box">
      <div class="head_top">
        <p>案例展示</p>
        <p>CASE PRESENTATION</p>
      </div>
      <div class="head_button">
        <div class="ation">
          <div
            class="navi"
            v-for="(item, index) in content"
            :key="index"
            @mouseover="active = index"
          >
            {{ item.text }}
            <i v-show="active == index" class="navi_i"></i>
          </div>
        </div>

        <div
          class="content"
          v-for="(item, index) in content"
          :key="index"
          v-show="active == index"
        >
          <div
            class="content_itel"
            v-for="(itle, index) in item.imgs"
            :key="index"
          >
            <img class="img" :src="itle.img" alt="" />
            <div class="position">
              <img class="tianmao" :src="itle.imgr" alt="" />
              <img class="qingzhen" :src="itle.imgf" alt="" />
              <div class="wygs">
                <p class="p">{{ itle.texttop }}</p>
                <p class="pr">{{ itle.textbott }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="butt">
        <button class="goda">了解更多</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  //经典案例循环渲染
  data() {
    return {
      active: 0,
      content: [
        {
          text: "网红孵化",
          imgs: [
            {
              img: require("@/assets/imgs/inedx/wh1.png"),
              texttop: "粥哈哈",
              textbott: "抖音ID：20191206qq",
            },
            {
              img: require("@/assets/imgs/inedx/wh2.png"),
              texttop: "一只骆驼",
              textbott: "抖音ID:CPDDONE",
            },
            {
              img: require("@/assets/imgs/inedx/wh3.png"),
              texttop: "果冻Cici",
              textbott: "抖音ID：Cici0706_",
            },
            {
              img: require("@/assets/imgs/inedx/wh4.png"),
              texttop: "梓萱",
              textbott: "抖音ID：zixuan888666",
            },
          ],
        },
        {
          text: "电商孵化",
          imgs: [
            {
              img: require("@/assets/imgs/inedx/zqtp.png"),
              imgr: require("@/assets/imgs/inedx/tianmao.png"),
              imgf: require("@/assets/imgs/inedx/zq.png"),
              texttop: "四川青珍旗舰店水果天猫店",
              textbott: "芒果月销400W+",
            },
            {
              img: require("@/assets/imgs/inedx/cxtztp.png"),
              imgr: require("@/assets/imgs/inedx/tianmao.png"),
              imgf: require("@/assets/imgs/inedx/od.png"),
              texttop: "殴度旗舰店",
              textbott: "月销40W+",
            },
            {
              img: require("@/assets/imgs/inedx/qpltp.png"),
              imgr: require("@/assets/imgs/inedx/tianmao.png"),
              imgf: require("@/assets/imgs/inedx/qql.png"),
              texttop: "七匹狼专卖店",
              textbott: "月销400W+",
            },
            {
              img: require("@/assets/imgs/inedx/md.png"),
              imgr: require("@/assets/imgs/inedx/pdd.png"),
              imgf: require("@/assets/imgs/inedx/md_kt.png"),
              texttop: "美的云专卖旗舰店",
              textbott: "月销200W+",
            },
          ],
        },
        {
          text: "品牌孵化",
          imgs: [
            {
              img: require("@/assets/imgs/inedx/szbtp.png"),
              imgf: require("@/assets/imgs/inedx/szb.png"),
              texttop: "抓狂口袋饼",
            },
            {
              img: require("@/assets/imgs/inedx/cxtzt.png"),
              imgf: require("@/assets/imgs/inedx/cxtz.png"),
              texttop: "诚旭投资",
            },
            {
              img: require("@/assets/imgs/inedx/ycywtp.png"),
              imgf: require("@/assets/imgs/inedx/ycyw.png"),
              texttop: "晏巢燕窝",
            },
            {
              img: require("@/assets/imgs/inedx/sxcztp.png"),
              imgf: require("@/assets/imgs/inedx/sxcz.png"),
              texttop: "色象彩妆",
            },
          ],
        },
      ],
    };
  },
  methods: {},
};
</script>

<style scoped lang="less">
.head {
  width: 100%;
  background-color: #1c1f26;
  margin-bottom: 80px;
  .head_box {
    max-width: 1200px;
    min-width: 1200px;
    margin: 0 auto;
    height: 1000px;
    overflow: hidden;
    .head_top {
      text-align: center;
      line-height: 45px;
      margin-top: 90px;
      p:nth-child(1) {
        font-size: 30px;
        font-weight: 300;
        color: #ffffff;
        line-height: 26px;
      }
      p:nth-child(2) {
        font-size: 20px;
        font-weight: 300;
        color: #ffffff;
      }
    }
    .head_button {
      .ation {
        width: 540px;
        height: 35px;
        margin: 0 auto;
        text-align: center;
        margin-top: 40px;
        .navi {
          float: left;
          font-size: 18px;
          width: 180px;
          font-weight: bold;
          color: #ffffff;
          line-height: 35px;
          position: relative;
          cursor: pointer;
          .navi_i {
            position: absolute;
            left: 5px;
            top: 33px;
            display: inline-block;
            width: 170px;
            height: 2px;
            background: white;
          }
        }
      }
      .content {
        width: 1200px;
        height: 445px;
        margin: 0 auto;
        margin-top: 60px;
        overflow: hidden;
        border-radius: 10px;
        .content_itel {
          float: left;
          overflow: hidden;
          .img {
            width: 300px;
            height: 395px;
          }
          .position {
            width: 300px;
            height: 392px;
            background: none;
            position: relative;
            top: -395px;
            left: 0px;
            transition: 0.5s;
            overflow: hidden;
            cursor: pointer;
            .tianmao {
              padding: 23px 0 0 20px;
              display: none;
            }
            .qingzhen {
              position: absolute;
              left: 0;
              right: 0;
              top: 130px;
              bottom: 0;
              align-items: flex-end;
              margin: 0 auto;
              display: none;
            }
            .wygs {
              position: absolute;
              left: 30px;
              top: 470px;
              transition: 0.7s;
              .p {
                font-size: 19px;
                font-weight: bold;
                color: #ffffff;
                line-height: 38px;
                transition: 0.7s;
              }
              .pr {
                font-size: 17px;
                font-weight: 300;
                color: #ffffff;
              }
            }
          }
        }
        .content_itel:hover .position {
          background: rgba(0, 0, 0, 0.3);
          transition: 0.5s;
        }
        .content_itel:hover .wygs {
          position: absolute;
          left: 30px;
          top: 330px;
          transition: 0.7s;
        }
       .content_itel:hover .qingzhen {
         display: block;
       }
       .content_itel:hover .tianmao {
         display: block;
       }
      }
    }

    .butt {
      text-align: center;
      .goda {
        width: 155px;
        height: 40px;
        border: 1px solid #949497;
        border-radius: 10px;
        font-size: 20px;
        font-weight: 300;
        color: #949497;
        letter-spacing: 2px;
        outline: none;
        margin-top: 80px;
      }
      .goda:hover {
        color: #ffffff;
        border: 3px solid #ffffff;
      }
    }
  }
}
</style>
