<template>
  <!-- 统计 -->
  <div class="showoff">
    <div class="showoff_box">
      <div class="showoff_top">
        <div
          class="content"
          v-for="(item, index) in content"
          :key="index"
          @mouseover="active = index"
        >
          <img :src="item.img" alt="" />
          <div class="position">
            <p>{{ item.head }}</p>
            <p>
              {{ item.text }}
            </p>
            <a class="a" href="#">点击了解</a>
            <a class="ters_im" href="#"
              ><img src="@/assets/imgs/inedx/jrs.png" alt=""
            /></a>
          </div>
        </div>
      </div>
      <div
        class="showoff_botto"
        v-for="(item, index) in content"
        :key="index"
        v-show="active == index"
      >
        <div v-for="(itlm, index) in item.cont" :key="index">
          <ul>
            <li>{{ itlm.head }}</li>
            <li>
              {{ itlm.text }}
            </li>
          </ul>
          <img :src="itlm.img" alt="" />
          <img class="bf_iemt" src="@/assets/imgs/inedx/bf.png" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      active: 0,
      content: [
        {
          img: require("@/assets/imgs/inedx/wh.png"),
          head: "网红孵化",
          text:
            "公司旗下孵化海内外网红主播超6000位，为企业全方位提供带货营销驻场支持.",
          cont: [
            {
              head: "网红新时代",
              text:
                "我们旗下拥有:网红孵化、电商孵化、品牌孵化、增值服务、销售推、微站、广告助手等解决方案，帮助客户在新零售时代提高运营效率和盈利能力。昆仑虚精准营销业务以大数据、智能算法、营销自动化等技术及优质媒体源。",
              img: require("@/assets/imgs/inedx/sp.png"),
            },
          ],
        },
        {
          img: require("@/assets/imgs/inedx/ds.png"),
          head: "电商孵化",
          text:
            "我们会将根据您的行业所包含的特征以及消费人群等各方面进行调研考察，针对其采取 不一样的助力方案.",
          cont: [
            {
              head: "电商新时代",
              text:
                "我们旗下拥有:网红孵化、电商孵化、品牌孵化、增值服务、销售推、微站、广告助手等解决方案，帮助客户在新零售时代提高运营效率和盈利能力。昆仑虚精准营销业务以大数据、智能算法、营销自动化等技术及优质媒体源。",
              img: require("@/assets/imgs/inedx/sp.png"),
            },
          ],
        },
        {
          img: require("@/assets/imgs/inedx/pp.png"),
          head: "品牌孵化",
          text:
            "策划团队分析，让品牌在最佳时间上线品牌市场响应扩大，合作伙伴有完正的基础更快获得投资.",
          cont: [
            {
              head: "品牌新时代",
              text:
                "我们旗下拥有:网红孵化、电商孵化、品牌孵化、增值服务、销售推、微站、广告助手等解决方案，帮助客户在新零售时代提高运营效率和盈利能力。昆仑虚精准营销业务以大数据、智能算法、营销自动化等技术及优质媒体源。",
              img: require("@/assets/imgs/inedx/sp.png"),
            },
          ],
        },
      ],
    };
  },
};
</script>

<style scoped lang="less">
.showoff {
  width: 100%;
  background: #ffffff;
  .showoff_box {
    max-width: 1290px;
    min-width: 1290px;
    margin: 0 auto;
    height: 1050px;
    // background: lemonchiffon;

    .showoff_top {
      margin: 0 auto;
      .content {
        float: left;
        margin-right: 30px;
        position: relative;
        left: 0;
        top: -85px;
        cursor: pointer;
        transition: all 0.5s;
        > img {
          width: 410px;
          height: 500px;
          border-radius: 20px;
        }
        .position {
          height: 233px;
          position: absolute;
          left: 0;
          top: 245px;
          padding: 0px 60px 0px 45px;
          cursor: pointer;
          p:nth-child(1) {
            font-size: 30px;
            letter-spacing: 3px;
            font-weight: unset;
            color: #ffffff;
            margin-bottom: 22px;
          }
          p:nth-child(2) {
            font-size: 15px;
            letter-spacing: 2px;
            line-height: 20px;
            color: #e6e6e6;
            font-weight: 300;
            margin-bottom: 90px;
          }
          .a {
            font-size: 18px;
            font-weight: 600;
            color: #ffffff;
          }
          .ters_im {
            float: right;
            > img {
              transition: all 0.5s;
            }
          }
        }
      }
      .content:hover {
        position: relative;
        left: 0;
        top: -150px;
        transition: all 0.5s;
      }
      .content:hover .ters_im > img {
        opacity: 0.9;
        transform: scale(1.3);
        transition: all 0.5s;
      }
      .content:nth-child(3) {
        margin: 0;
      }
    }
    .showoff_botto {
      ul {
        float: left;
        max-width: 400px;
        padding: 110px 0 0 0;
        li:nth-child(1) {
          font-size: 35px;
          font-family: unset;
          font-weight: initial;
          letter-spacing: 1px;
          color: #333333;
          margin-bottom: 40px;
        }
        li:nth-child(2) {
          font-size: 18px;
          font-weight: 300;
          letter-spacing: 1px;
          color: #444444;
          line-height: 30px;
        }
      }
      img {
        float: right;
        width: 810px;
        height: 485px;
        border-radius: 10px;
        cursor: pointer;
      }
      .bf_iemt {
        width: 108px;
        height: 108px;
        position: relative;
        left: 460px;
        top: -130px;
      }
    }
  }
}
</style>
