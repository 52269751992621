<template>
  <!-- 统计 -->
  <div class="partner">
    <div class="partner_box">
      <div class="partner_top">
        <p>合作伙伴</p>
        <p>COOPERATIVE PARTNER</p>
      </div>
      <div class="partner_botton">
        <div class="img">
          <img class="mie" src="@/assets/imgs/inedx/left.png" alt="" />
          <div class="content" >
            <div class="content_ietm" v-for="(itlm, index) in content" :key="index">
              <img :src="itlm.list" alt="" />
              <p>{{itlm.text}}</p>
            </div>
           
          </div>
          <img class="mei" src="@/assets/imgs/inedx/right.png" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      content: [
        {
          list: require("@/assets/imgs/inedx/baidu.png"),
          text: "百度贴吧",
        },
        {
          list: require("@/assets/imgs/inedx/wunsun.png"),
          text: "四川扭一扭科技有限公司",
        },
        {
          list: require("@/assets/imgs/inedx/tx.png"),
          text: "腾讯网",
        },
        {
          list: require("@/assets/imgs/inedx/dinali.png"),
          text: "朝歌互娱",
        },
        {
          list: require("@/assets/imgs/inedx/wangyi.png"),
          text: "网易邮箱",
        },
      ],
    };
  },
  methods: {
    getMethod() {
      console.log(111);
    },
    getMethoe() {
      console.log(22);
    },
  },
};
</script>

<style scoped lang="less">
.partner {
  width: 100%;
 
  .partner_box {
    height: 325px;
    .partner_top {
      text-align: center;
      line-height: 45px;
      p:nth-child(1) {
        font-size: 35px;
        font-weight: bold;
        color: #403c3c;
      }
      p:nth-child(2) {
        font-size: 20px;
        font-family: unset;
        font-weight: 400;
        color: #8c8c8c;
      }
    }
  }
  .partner_botton {
    max-width: 1290px;
    margin: 0 auto;
    text-align: center;

    .content {
      max-width: 1200px;
      margin: 0 auto;
      margin-top: 100px;
      position: relative;
      top: -35px;
      left: 0px;
      .content_ietm {
        float: left;
        margin: 0 auto;
        margin-right: 65px;
        p {
          margin-top: 10px;
          font-size: 17px;
          font-weight: bold;
          color: #24344b;
        }
      }
      .content_ietm:nth-child(5) {
   
        margin: 0;
      }
    }
    .img {
      .mie {
        float: left;
        cursor: pointer;
      }
      .mei {
  
        cursor: pointer;
      }
    }
  }
}
</style>
